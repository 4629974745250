
import { defineComponent, onMounted } from "vue";
import {getCompanyAgreementStatus}  from "@/api/companypolicy";


export default defineComponent({
  name: "company-policy", 
  methods: {
    async get_CompanyAgreementStatus(){ 
      const response = await getCompanyAgreementStatus();

      if((response.data.investmentSchemeAdvisoryAgree == false || response.data.policyAgree == false ||
          response.data.productPolicyAgree == false) && response.data.isOverriden == "false"){
            this.$router.push('/policy') ;
      }

      
    },
  },
  mounted(){
      this.get_CompanyAgreementStatus();
  },
  
  data(){
      return { 
      }
  },
  components: {
  },
  props: {
    widgetClasses: String
  }
});

