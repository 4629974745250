import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_4 = { class: "col-xxl-12 mb-5" }
const _hoisted_5 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")
  const _component_KTAside = _resolveComponent("KTAside")
  const _component_KTAsideECP = _resolveComponent("KTAsideECP")
  const _component_KTAsideEDP = _resolveComponent("KTAsideEDP")
  const _component_KTAsideBIBO = _resolveComponent("KTAsideBIBO")
  const _component_KTAsideIGlow = _resolveComponent("KTAsideIGlow")
  const _component_KTAsideECC = _resolveComponent("KTAsideECC")
  const _component_KTHeader = _resolveComponent("KTHeader")
  const _component_KTToolbar = _resolveComponent("KTToolbar")
  const _component_CompanyPolicy = _resolveComponent("CompanyPolicy")
  const _component_ECShopAdvisory = _resolveComponent("ECShopAdvisory")
  const _component_router_view = _resolveComponent("router-view")
  const _component_KTFooter = _resolveComponent("KTFooter")
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")
  const _component_KTDrawerMessenger = _resolveComponent("KTDrawerMessenger")
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_1, [
      (_ctx.asideEnabled && _ctx.planType === 'ovi')
        ? (_openBlock(), _createBlock(_component_KTAside, {
            key: 0,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true),
      (_ctx.asideEnabled && _ctx.planType === 'ecp')
        ? (_openBlock(), _createBlock(_component_KTAsideECP, {
            key: 1,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true),
      (_ctx.asideEnabled && _ctx.planType === 'edp')
        ? (_openBlock(), _createBlock(_component_KTAsideEDP, {
            key: 2,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true),
      (_ctx.asideEnabled && _ctx.planType === 'bibo')
        ? (_openBlock(), _createBlock(_component_KTAsideBIBO, {
            key: 3,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true),
      (_ctx.asideEnabled && _ctx.planType === 'iglow')
        ? (_openBlock(), _createBlock(_component_KTAsideIGlow, {
            key: 4,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true),
      (_ctx.asideEnabled && _ctx.planType === 'ecc')
        ? (_openBlock(), _createBlock(_component_KTAsideECC, {
            key: 5,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
        _createVNode("div", _hoisted_3, [
          (_ctx.subheaderDisplay && !_ctx.isDocPage)
            ? (_openBlock(), _createBlock(_component_KTToolbar, {
                key: 0,
                breadcrumbs: _ctx.breadcrumbs,
                title: _ctx.pageTitle
              }, null, 8, ["breadcrumbs", "title"]))
            : _createCommentVNode("", true),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_CompanyPolicy),
            (_ctx.planType !== 'ecc')
              ? (_openBlock(), _createBlock(_component_ECShopAdvisory, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode("div", {
              class: {
              'container-fluid': _ctx.contentWidthFluid,
              container: !_ctx.contentWidthFluid
            }
            }, [
              _createVNode(_component_router_view)
            ], 2)
          ])
        ]),
        _createVNode(_component_KTFooter)
      ])
    ]),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTDrawerMessenger),
    _createVNode(_component_KTUserMenu),
    _createVNode(_component_KTCreateApp)
  ], 64))
}