const pt = String(localStorage.getItem("pt"));

const DocMenuConfig: object = [
  
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboardecc",
        svgIcon: "media/icons/duotone/Home/Home.svg",
        fontIcon: "bi-app-indicator"
      },
      {
        heading: "transactions",
        route: "/transactions",
        svgIcon: "media/icons/duotone/Design/Edit.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "encashment",
        route: "/encashment",
        svgIcon: "media/icons/duotone/Shopping/Money.svg",
        fontIcon: "bi-layers"
      },
    ]
  },
  {
    heading: "settings",
    route: "/settings",
    pages: [ 
      {
        heading: "profile",
        route: "/settings/profile/",
        svgIcon: "media/icons/duotone/General/User.svg",
            fontIcon: "bi-archive"
      },  
      {
        heading: "Log-out",
        route: "/logout",
        svgIcon: "media/icons/duotone/Navigation/Sign-out.svg",
            fontIcon: "bi-archive"
      }, 
    ]
  },
];


export default DocMenuConfig;
