
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  data() {
    return {   
        footer_title: "",
        footer_href: "",

    }
  }, 
  setup() {
    return {
      footerWidthFluid
    };
  },
  mounted() {
      let pt = localStorage.getItem('pt');
      if(pt === "ecp") {
        this.footer_title = "Empowered Consumerism";
        this.footer_href = "https://ec5.empoweredconsumerism.com/";
      }
      if(pt === "ovi") {
        this.footer_title = "Orbix Victus International";
        this.footer_href = "https://my.orbixvictus.com/";
      }
      if(pt === "edp") {
        this.footer_title = "Economy Driver 3.0";
        this.footer_href = "https://my.economydriver.com/";
      }
  }
});
