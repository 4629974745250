import request from '@/utils/request'

// get company policy status
export function getCompanyAgreementStatus() {
  return request({ url: 'vgetPolicyAgreementStatus', method: 'get'})
} 



export function companyPolicyAgree() {
  return request({ url: 'vAgreeToCompanyPolicy', method: 'get'})
} 