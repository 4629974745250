import request from '@/utils/request'

export function vCheckOverride() {
  return request({ url: 'vcheckoverride', method: 'get'})
} 



//Override Search Results
export function searchUserForOverride(params) {
  return request({ url: 'searchUser', method: 'post', data: params })
} 


//Override Now
export function overridenow(params) {
  return request({ url: 'ovrridenow', method: 'post', data: params })
} 


//Release Override
export function releaseOverride(params) {
  return request({ url: 'releaseoverride', method: 'post', data: params })
} 