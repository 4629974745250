
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { isDocPage, version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import DocMenuConfig from "@/core/config/DocMenuConfig";

import MainMenuConfig from "@/core/config/MainMenuConfig";
import MainMenuConfig_BIBO from "@/core/config/MainMenuConfig_BIBO";
import MainMenuConfig_ECC from "@/core/config/MainMenuConfig_ECC";




import {vCheckOverride}  from "@/api/override";

import { getCurrentUser } from '../../utils/index';
import {getUserCountryCode} from '@/api/dashboard';

export default defineComponent({
  name: "kt-menu",
  components: {},
  methods: {
    async checkIfHasOverrideAccess(){ 
      const response = await vCheckOverride();
      this.override = response.data.override;
    },

  async getUserCcode(){ 
      const response = await getUserCountryCode();
      this.ccode = response.data.ccode;
    },  

  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      isDocPage,
      DocMenuConfig,
      MainMenuConfig,
      MainMenuConfig_BIBO,
      MainMenuConfig_ECC,
      asideMenuIcons,
      version,
      translate
    };
  },
  data() {
    return {
        current_usid: "" as string,
        override : false as boolean,
        isBiboOnly : "" as string,
        isShowAutoPMA : "" as string,
        isShowBibo: "" as string,
        pt : "" as string,
        packagetype : "" as string,
        ccode : 0 as number
    }
  },
  mounted() {
    let user = getCurrentUser(); 
    
    if(user !== undefined){
        this.current_usid = user.usid ;
        this.isBiboOnly = String(localStorage.getItem("bibo-only"));
        this.isShowAutoPMA = String(localStorage.getItem("sXhYoZwAABuCtDoEPFMGA")); 
        this.isShowBibo = String(localStorage.getItem("oZwAuCtDoAYBEPsXhFMGA"));  
        this.pt = String(localStorage.getItem("pt"));
        this.packagetype = String(localStorage.getItem("pVaAcNkPaOgGeItRyEpAeL"));
    }


    

    this.checkIfHasOverrideAccess();
    this.getUserCcode();
  }

});
