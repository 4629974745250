const pt = String(localStorage.getItem("pt"));

const showAutoPMA = String(localStorage.getItem("sXhYoZwAABuCtDoEPFMGA"));


const DocMenuConfig: object = [
  
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard/reminderspage",
        svgIcon: "media/icons/duotone/Home/Home.svg",
        fontIcon: "bi-app-indicator"
      }, 
      {
        heading: "autopma",
        route: "/autopma",
        svgIcon: "media/icons/duotone/Design/Edit.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "transactions",
        route: "/transactions",
        svgIcon: "media/icons/duotone/Design/Edit.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "encashment",
        route: "/encashment",
        svgIcon: "media/icons/duotone/Shopping/Money.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "reorder",
        route: "/reorder",
        svgIcon: "media/icons/duotone/Shopping/Settings.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "stairstep",
        route: "/stairstep",
        svgIcon: "media/icons/duotone/Home/Stairs.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "unilevel",
        route: "/unilevel",
        svgIcon: "media/icons/duotone/Interface/Line-03-Up.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "upp",
        route: "/upp",
        svgIcon: "media/icons/duotone/General/Thunder.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "BIBO COMMISSIONS",
        route: "/bibo",
        svgIcon: "media/icons/duotone/Devices/Phone.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "BIBO PLACEMENT",
        route: "/biboendorsers",
        svgIcon: "media/icons/duotone/Devices/Phone.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "aefc",
        route: "/aefc",
        svgIcon: "media/icons/duotone/Devices/Phone.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "myrebates",
        route: "/myrebates",
        svgIcon: "media/icons/duotone/General/Thunder.svg",
        fontIcon: "bi-layers"
      },
      
      {
        heading: "estore",
        route: "/estore",
        svgIcon: "media/icons/duotone/General/Star.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: (pt === "ovi" ? "Digital Partners" : "recruitments"),
        route: "/recruitments",
        svgIcon: "media/icons/duotone/General/Star.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "directsponsored",
        route: "/directsponsored",
        svgIcon: "media/icons/duotone/General/Star.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "productgc",
        route: "/productgc",
        svgIcon: "media/icons/duotone/General/Star.svg",
        fontIcon: "bi-layers"
      },
/*
      {
        heading: "biboactivations",
        route: "/biboactivations",
        svgIcon: "media/icons/duotone/General/Star.svg",
        fontIcon: "bi-layers"
      }
      */

      
      // ,
      // {
      //   heading: "estoretrans",
      //   route: "/estoretrans",
      //   svgIcon: "media/icons/duotone/Interface/Settings-02.svg",
      //   fontIcon: "bi-layers"
      // }
    ]
  },
  {
    heading: "onlineshop",
    route: "/onlineshop",
    pages: [
      {
        heading: "shopcodes",
        route: "/onlineshop/shopcodes/",
        svgIcon: "media/icons/duotone/Shopping/Cart1.svg",
        fontIcon: "bi-archive"
      }, 
      
      {
        heading: "shoppurchases",
        route: "/onlineshop/shoppurchases/",
        svgIcon: "media/icons/duotone/Shopping/Price1.svg",
        fontIcon: "bi-archive"
      }, 
      {
        heading: "retailsales",
        route: "/onlineshop/retailsales/",
        svgIcon: "media/icons/duotone/Shopping/Price1.svg",
        fontIcon: "bi-archive"
      }, 
      {
        heading: "ecwallet",
        route: "/onlineshop/ecwallet/",
        svgIcon: "media/icons/duotone/Shopping/Wallet.svg",
        fontIcon: "bi-archive"
      }
    ]
  },
  {
    heading: "networkmenu",
    route: "/networkmenu",
    pages: [
     /* {
        heading: "networksales",
        route: "/networkmenu/networksales/",
        svgIcon: "media/icons/duotone/Code/Compiling.svg",
        fontIcon: "bi-archive"
      }, */
      
      {
        heading: "genealogy",
        route: "/networkmenu/genealogy/",
        svgIcon: "media/icons/duotone/Design/Triangle.svg",
        fontIcon: "bi-archive"
      },
      
      
      {
        heading: "ppssetup",
        route: "/networkmenu/ppssetup/",
        svgIcon: "media/icons/duotone/Design/Triangle.svg",
        fontIcon: "bi-archive"
      }
    ]
  },
  // {
  //   heading: "ovimenu",
  //   route: "/ovimenu",
  //   pages: [
  //     {
  //       heading: "ovicoverageinfo",
  //       route: "/ovimenu/ovicoverageinfo/",
  //       svgIcon: "media/icons/duotone/Code/Compiling.svg",
  //       fontIcon: "bi-archive"
  //     },
      
  //     {
  //       heading: "ovicoveragetransfer",
  //       route: "/ovimenu/ovicoveragetransfer/",
  //       svgIcon: "media/icons/duotone/Code/Compiling.svg",
  //       fontIcon: "bi-archive"
  //     }
  //   ]
  // },
  {
    heading: "settings",
    route: "/settings",
    pages: [ 
      {
        heading: "profile",
        route: "/settings/profile/",
        svgIcon: "media/icons/duotone/General/User.svg",
            fontIcon: "bi-archive"
      },  
      {
        heading: "Log-out",
        route: "/logout",
        svgIcon: "media/icons/duotone/Navigation/Sign-out.svg",
            fontIcon: "bi-archive"
      }, 
    ]
  },
];

export default DocMenuConfig;
