
import { defineComponent } from "vue";
import { themeName } from "@/core/helpers/documentation";

export default defineComponent({
  name: "kt-explore",
  setup() {
    return {
      themeName
    };
  }
});
