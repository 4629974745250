
import { defineComponent, onMounted, ref } from "vue";
import { getDashboardECPWelcomeData, getUserCountryCode, getBankAccountNo } from "@/api/dashboard"


export default defineComponent({
  name: "ecshopadvisory",
  components: {
  },
  data() {
    return { 
      pt : "",
      ecp_welcome_data: {},
      usid : "",
      unilevelMaintenancePromptMessage : "",
      unilevelMaintenancePromptMessage1 : "",
      unilevelMaintenancePromptMessage2 : "",
      isShowPMAWarning : false,
      isEnabledECCSRNotification : false,
      csrMessage : "",
      ccode : -1,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      donationImgUrl : "",
      bankAccountNo : ""
    }
  },
  mounted(){
    this.pt = String(localStorage.getItem("pt"));
    this.usid = String(localStorage.getItem("ec_current_usid"));
    this.getDashboardECPWelcomeData();
    this.getUserCcode();
    this.getBankAccountNumber();
    this.onResize();
  },
  methods : {
    async getDashboardECPWelcomeData(){
      var self = this;
      const response = await getDashboardECPWelcomeData();
      console.log(response.data);
      self.ecp_welcome_data = response.data;
      self.unilevelMaintenancePromptMessage = response.data.unilevelMaintenancePromptMessage;

      this.isEnabledECCSRNotification = response.data.isEnabledECCSRNotification;
      this.csrMessage = response.data.orderid;
      
      self.unilevelMaintenancePromptMessage1 = self.unilevelMaintenancePromptMessage.split("complete your maintenance NOW")[0];
      self.unilevelMaintenancePromptMessage2 = self.unilevelMaintenancePromptMessage.split("complete your maintenance NOW")[1];

      if(self.unilevelMaintenancePromptMessage.includes("To continue")
       && response.data.is10DaysLeftForPMA){
        self.isShowPMAWarning = true;
      } else {
        self.isShowPMAWarning = false;
      }
    },
    async getUserCcode() {
      const response = await getUserCountryCode();
        if(response.data !== undefined ) {
          this.ccode = response.data.ccode;
        }
    },

    async getBankAccountNumber() {
      const response = await getBankAccountNo();
        if(response.data !== undefined ) {
          this.bankAccountNo = response.data.bankAccountNo;
        }
    },
    onResize() {
      //this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      if(this.windowWidth <= 450){
        this.donationImgUrl = "https://images.empoweredconsumerism-static-files.com/ecp/ALIVE_DONATION_CARINA_MOBILE.jpg";
      } else {
        this.donationImgUrl = "https://images.empoweredconsumerism-static-files.com/ecp/ALIVE_DONATION_CARINA_PC.jpg";
      }
    }
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
    });
    
  }
});
