
import { defineComponent } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import AccountList from "@/components/dashboard/AccountList.vue";
import {releaseOverride}  from "@/api/override";
import { UserRole } from '@/utils/auth.roles';
import { setCurrentUser,getCurrentUser } from '../../utils/index';

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
    AccountList
  },
  data() {
    return {
        avatar: "media/avatars/150-26.jpg" as string,
        user: {},
        isOverriden: false,
        isBiboOnly : false,
    }
  },
  methods : {
    
    async releaseOverrideNow(){
        localStorage.removeItem('override_cifid');
        localStorage.removeItem('ec_current_usid');

        var param = {
        "x" : 0
        };
        const response = await releaseOverride(param);

         const user = {
                    un: response.data.email,
                    fname: response.data.fname,
                    mname: response.data.mname,
                    lname: response.data.lname,
                    avatar: response.data.avatar,
                    role: UserRole.User,
                    usid: response.data.usid,
                    email: response.data.email,
                    address: response.data.address,
                    bday: response.data.bday,
                    mobile: response.data.mobile
                };
                setCurrentUser(user);

              localStorage.ec_current_usid = response.data.usid;
        location.reload();
    },
    async checkIfBiboOnly(){
      var biboonly = String(localStorage.getItem("bibo-only"));

      if(biboonly == "true"){
        this.isBiboOnly = true;
      } else {
        this.isBiboOnly = false;
      }
    }

  },
  mounted() {
    this.checkIfBiboOnly();
    let user = getCurrentUser();
    this.user = getCurrentUser();

    let override_cifid  = localStorage.getItem('override_cifid');
    if(user !== undefined){
        this.avatar = user.avatar ;
    }
    if (override_cifid != null) {
        this.isOverriden = true;
    }
  }
});
