const pt = String(localStorage.getItem("pt"));

const DocMenuConfig: object = [
  
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboardbibo",
        svgIcon: "media/icons/duotone/Home/Home.svg",
        fontIcon: "bi-app-indicator"
      },
      {
        heading: "BIBO COMMISSIONS",
        route: "/bibo",
        svgIcon: "media/icons/duotone/Devices/Phone.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "transactions",
        route: "/transactions",
        svgIcon: "media/icons/duotone/Design/Edit.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "encashment",
        route: "/encashment",
        svgIcon: "media/icons/duotone/Shopping/Money.svg",
        fontIcon: "bi-layers"
      },
    ]
  },
  {
    heading: "onlineshop",
    route: "/onlineshop",
    pages: [
      {
        heading: "shopcodes",
        route: "/onlineshop/shopcodes/",
        svgIcon: "media/icons/duotone/Shopping/Cart1.svg",
        fontIcon: "bi-archive"
      }, 
      
      {
        heading: "shoppurchases",
        route: "/onlineshop/shoppurchases/",
        svgIcon: "media/icons/duotone/Shopping/Price1.svg",
        fontIcon: "bi-archive"
      }, 
      {
        heading: "retailsales",
        route: "/onlineshop/retailsales/",
        svgIcon: "media/icons/duotone/Shopping/Price1.svg",
        fontIcon: "bi-archive"
      }
    ]
  },
  {
    heading: "settings",
    route: "/settings",
    pages: [ 
      {
        heading: "profile",
        route: "/settings/profile/",
        svgIcon: "media/icons/duotone/General/User.svg",
            fontIcon: "bi-archive"
      },  
      {
        heading: "Log-out",
        route: "/logout",
        svgIcon: "media/icons/duotone/Navigation/Sign-out.svg",
            fontIcon: "bi-archive"
      }, 
    ]
  },
];


export default DocMenuConfig;
